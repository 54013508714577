import React from "react";

const HelpDialog = ({ textSize, toggleHelp = () => {} }) => {
  return (
    <div>
      <div className="fixed inset-0 bg-gray-800 opacity-50"></div>
      <div className="fixed inset-0 flex items-center justify-center p-2">
        <div
          className="relative bg-[var(--neutral-white)] p-6 rounded-lg shadow-xl w-[75%]"
          style={{ fontSize: `${textSize}rem` }}
        >
          <div className="relative overflow-y-scroll h-[100%]">
            <div className="flex flex-col items-center justify-center mb-4">
              <div className="text-[20px] leading-9 font-[600] mb-4">
              For any guidance
                </div>
                <div className="mb-4">
                <img src={"/Assets/Images/phoneCall.svg"} alt="Help" />
                </div>
                <div className="text-[16px]  font-[400] leading-6 text-center mb-4">
                Kindly contact your supervisor <br/> or <br/>This helpline number : 
                <div className="text-[16px]  font-[400] leading-6 text-center mb-6 text-[var(--link-color)] underline">
                  011-4999 4999
                </div>
                </div>
            </div>
          </div>
          <div
            className="absolute top-[-1.25rem] right-[-1.25rem] rounded-full bg-[var(--almost-black)] flex items-center justify-center p-1"
            onClick={toggleHelp}
          >
            <img src={"./Assets/Images/crossIcon.svg"} alt="Close"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpDialog;
