const SourcesChatBlock = ({sourceData, openSourceDialog = () => { }}) => {
    return (
        <div>
            <div className='rounded-full bg-[var(--neutral-white)] flex items-center justify-center p-2' onClick={() => openSourceDialog(sourceData)}>
                <img src={'./Assets/Images/sourcesIcon.svg'}/>
            </div>
        </div>
    )
}

export default SourcesChatBlock;