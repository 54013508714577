// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dateStamp {
    width: 32vw;
    height: 2.6vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: var(--date-background);
    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.16);
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    color: var(--dark-neutral-2);
}`, "",{"version":3,"sources":["webpack://./src/Shared/Components/DateStamp/DateStamp.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,kBAAkB;IAClB,wCAAwC;IACxC,+CAA+C;IAC/C,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,4BAA4B;AAChC","sourcesContent":[".dateStamp {\n    width: 32vw;\n    height: 2.6vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-shrink: 0;\n    border-radius: 4px;\n    background-color: var(--date-background);\n    box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.16);\n    text-align: center;\n    font-size: 13px;\n    font-weight: 600;\n    color: var(--dark-neutral-2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
