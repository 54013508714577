import React from 'react';
import "./DateStamp.css";

const DateStamp = ({dateValue}) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let date = dateValue.getDate();
    let month = months[dateValue.getMonth()];
    let year = dateValue.getFullYear();
    const formattedDate = date + " "+ month+ " "+ year;
  return (
    <div className='dateStamp'>{formattedDate}</div>
  )
}

export default DateStamp