// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Color Constants */
:root {
    /* Primaries */
    --primary-dark: #434DCF;
    --primary-medium: #6F78DE;
    --primary-light: #DCDFFF;
    --primary-light-2: #F4F5FF;
    /* Neutrals (backgrounds/ layout blocks/ outlines) */
    --neutral-white: #FFFFFF;
    --light-neutral-1: #FDFDFD;
    --light-neutral-2: #F2F3F9;
    --light-neutral-3: #E4E6F3;
    --light-neutral-4: #CECAD7;
    --light-neutral-5: #D9D9D9;
    --light-neutral-6: #F7F8FB;
    --date-background: #F7F7F7;
    /* Neutrals (texts/icons) */
    --dark-neutral-1: #ACADAD;
    --dark-neutral-2: #78828C;
    --almost-black: #303336;
    --black: #000000;
    /* Specials */
    --star-yellow-1: #F19B50;
    --star-yellow-2: #FFCC7F;
    --star-yellow-3: #FFF8E7;
    --green-1: #1D7A59;
    --green-2: #A4E7C7;
    --green-3: #EBFBE9;
    --red-1: #EC4646;
    --red-2: #FFAFAF;
    --red-3: #FBE9F1;
    --link-blue: #0057FF;
    --system-blue: #3A82F7;
    --disclaimer-1: #514B28;
    --disclaimer-2: #D6D0B4;
    --disclaimer-3: #FFF5C1;
    --red-button: #B91F1F;
    --border-color: #434CB8;
    --link-color: #1C33FF;
    --active-speaker-div: #B6BAE6;
}`, "",{"version":3,"sources":["webpack://./src/color-constants.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,cAAc;IACd,uBAAuB;IACvB,yBAAyB;IACzB,wBAAwB;IACxB,0BAA0B;IAC1B,oDAAoD;IACpD,wBAAwB;IACxB,0BAA0B;IAC1B,0BAA0B;IAC1B,0BAA0B;IAC1B,0BAA0B;IAC1B,0BAA0B;IAC1B,0BAA0B;IAC1B,0BAA0B;IAC1B,2BAA2B;IAC3B,yBAAyB;IACzB,yBAAyB;IACzB,uBAAuB;IACvB,gBAAgB;IAChB,aAAa;IACb,wBAAwB;IACxB,wBAAwB;IACxB,wBAAwB;IACxB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,oBAAoB;IACpB,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,uBAAuB;IACvB,qBAAqB;IACrB,uBAAuB;IACvB,qBAAqB;IACrB,6BAA6B;AACjC","sourcesContent":["/* Color Constants */\n:root {\n    /* Primaries */\n    --primary-dark: #434DCF;\n    --primary-medium: #6F78DE;\n    --primary-light: #DCDFFF;\n    --primary-light-2: #F4F5FF;\n    /* Neutrals (backgrounds/ layout blocks/ outlines) */\n    --neutral-white: #FFFFFF;\n    --light-neutral-1: #FDFDFD;\n    --light-neutral-2: #F2F3F9;\n    --light-neutral-3: #E4E6F3;\n    --light-neutral-4: #CECAD7;\n    --light-neutral-5: #D9D9D9;\n    --light-neutral-6: #F7F8FB;\n    --date-background: #F7F7F7;\n    /* Neutrals (texts/icons) */\n    --dark-neutral-1: #ACADAD;\n    --dark-neutral-2: #78828C;\n    --almost-black: #303336;\n    --black: #000000;\n    /* Specials */\n    --star-yellow-1: #F19B50;\n    --star-yellow-2: #FFCC7F;\n    --star-yellow-3: #FFF8E7;\n    --green-1: #1D7A59;\n    --green-2: #A4E7C7;\n    --green-3: #EBFBE9;\n    --red-1: #EC4646;\n    --red-2: #FFAFAF;\n    --red-3: #FBE9F1;\n    --link-blue: #0057FF;\n    --system-blue: #3A82F7;\n    --disclaimer-1: #514B28;\n    --disclaimer-2: #D6D0B4;\n    --disclaimer-3: #FFF5C1;\n    --red-button: #B91F1F;\n    --border-color: #434CB8;\n    --link-color: #1C33FF;\n    --active-speaker-div: #B6BAE6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
