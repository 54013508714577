import React, { useEffect, useState } from "react";
import "./Device_readiness_Consent.css";
import ConsentContent from "../../../Shared/Components/ConsentContent/ConsentContent";
import { useNavigate } from "react-router-dom";
import { generateSpeech } from "../../../Actions/ChatActions";
import { getUserToken, stopPlayingAudio } from "../../../Actions/Utils";
import NextBtn from "../../../Shared/Components/NextBtn/NextBtn";

const DeviceReadinessConsent = ({project, selectedLanguage, audioRef }) => {
  const [consentGiven, setCosentGiven] = useState(false);
  const [showSpeakerActive, setShowSpeakerActive] = useState(true);
  const [requestController, setRequestController] = useState(null);
  const [constants, setConstants] = useState(null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   // if (!getUserToken()) {
  //   //   sessionStorage.clear();
  //   //   localStorage.clear();
  //   //   navigate("/login");
  //   // }
  //   const handleVisibilityChange = () => {
  //     stopPlayingAudio(audioRef);
  //     setShowSpeakerActive(false);
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   }
  // }, []);


  useEffect(() => {
    stopPlayingAudio(audioRef);
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);


  useEffect(() => {
    const handleAudio = async () => {
      if (constants && constants.consentAudio){
        const url = await generateSpeech(constants.consentAudio[selectedLanguage], selectedLanguage);
        const newAudio = new Audio(url);
        if (audioRef.current) {
          audioRef.current.pause();
        }
        if (requestController) {
          requestController.abort();
        }

        audioRef.current = newAudio;
        newAudio.play().catch((err) => {
          console.error("Error playing audio:", err);
        });

      }
    };

    if (showSpeakerActive) handleAudio();
  }, [showSpeakerActive, constants]);

  const handleConsentProceed = () => {
    localStorage.setItem("consent", true);
    stopPlayingAudio(audioRef);
    navigate("/device_readiness/form");
  };
  const backArrowClick = () => {
    navigate("/device_readiness/intro");
  };




  useEffect(() => {
    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setShowSpeakerActive(false);
    };

    const loadConstants = async () => {
      const module = await import(`../../../Constants/${project}/DeviceReadinessConsentContent`);
      setConstants(module);
    }

    loadConstants();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, []);

  if (!constants) {
    return <div>Loading...</div>;
  }

  const {   consent, consent_content, consent_headers, consnent_data, consentButton, heading} = constants;

  return (
    <div className="consent-page h-screen w-screen">
      <div className="w-full flex items-center justify-between bg-[var(--neutral-white)] p-4 text-[color:var(--black)] text-xl font-semibold">
      <div className="loginNextBtn   ">
          <NextBtn icon={"/Assets/Images/arrowLeftBlack.svg"} nextClick={backArrowClick} />
        </div>
        <div className=" flex items-center gap-3">
          <img className="h-10 w-10 rounded-full" src={"/Assets/Images/" + project + "/AppIcon.svg"} alt='Asha'/>
          {heading[selectedLanguage]}
        </div>
        {showSpeakerActive ? (
          <div
            className="bg-[#D5D7EE] rounded-full h-12 w-12 flex items-center justify-center p-1"
            onClick={() => {
              setShowSpeakerActive(!showSpeakerActive);
              stopPlayingAudio(audioRef);
            }}
          >
            <div className="bg-[var(--primary-dark)] rounded-full flex items-center justify-center h-[100%] w-[100%]">
              <img
                src={"/Assets/Images/whiteSpeaker.svg"}
                alt="Active Speaker"
              />
            </div>
          </div>
        ) : (
          <div
            onClick={() => {
              setShowSpeakerActive(!showSpeakerActive);
            }}
          >
            <img
              src={"/Assets/Images/crossSpeakerIcon.svg"}
              alt="Inactive Speaker"
            />
          </div>
        )}
      </div>
      <div className="flex flex-col px-4 py-6 gap-5">
        <div className="flex flex-col items-center py-5 px-1.5 rounded-2xl bg-[var(--neutral-white)] border border-[#EBEBEB] shadow-lg">
          {/* <div className="image-div rounded-lg"></div> */}
          <div className='text-[var(--almost-black)] font-["Noto_Sans"] font-bold text-lg tracking-wide leading-relaxed mt-4'>
            {consent_headers.header[selectedLanguage]}
          </div>
          <div className='text-[var(--dark-neutral-2)] font-["Noto_Sans"] text-base font-normal leading-double tracking-wide'>
            {/* {consent_headers.subheader[selectedLanguage]} */}
          </div>
          {consent_content.map((itm, idx) => {
            return (
              <div key={idx} className="flex flex-col py-4 px-5 gap-5">
                <ConsentContent
                  icon={itm.image}
                  header={itm.header[selectedLanguage]}
                  text={itm.text[selectedLanguage]}
                />
                {idx < consent_content.length - 1 ? (
                  <div className="divider" />
                ) : null}
              </div>
            );
          })}
          <div className="rounded-lg bg-[var(--light-neutral-2)] py-5 px-7 flex flex-col gap-2.5">
            <div className="text-[color:var(--black)] text-sm font-medium font-['Noto_Sans']">
              {consnent_data.question[selectedLanguage]}
            </div>
            <div className="text-[color:var(--dark-neutral-2)] text-sm font-normal font-['Noto_Sans']">
              {consnent_data.text[selectedLanguage]}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <div
            className={`consent-input-wrapper ${
              consentGiven ? "consent-given" : ""
            }`}
          >
            <input
              type="checkbox"
              className="custom-checkbox"
              value={consentGiven}
              onChange={() => {
                setCosentGiven(!consentGiven);
              }}
            />
            <div className="text-sm font-normal text-[color:var(--black)]">
              {consent[selectedLanguage]}
            </div>
          </div>
          {consentGiven && (
            <div
              className='w-full rounded-lg border border-[var(--light-neutral-4)] bg-[var(--primary-dark)] flex gap-2 py-3 px-4 justify-center items-center text-[color:var(--neutral-white)] text-base font-normal font-["Roboto"]'
              onClick={() => handleConsentProceed()}
            >
              {consentButton[selectedLanguage]}
              <img src={"/Assets/Images/nextWhiteIcon.svg"} alt="proceed" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeviceReadinessConsent;
