// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bottomBarDivRecord {
    border-top: 1px solid var(--light-neutral-3);
    background: #FFFFFF;
    margin-top: auto;
}

.optionsWrapperDiv {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Core/Components/BottomBarDeviceReadinessRecording/BottomBarDeviceReadinessRecording.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,qCAAqC;IACrC,kCAAkC;IAClC,SAAS;AACb","sourcesContent":[".bottomBarDivRecord {\n    border-top: 1px solid var(--light-neutral-3);\n    background: #FFFFFF;\n    margin-top: auto;\n}\n\n.optionsWrapperDiv {\n    margin-top: 1.5rem;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-template-rows: repeat(2, 1fr);\n    gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
