export const FeedbackConstant = {
    title: {en:"Was this answer helpful?", hi:"क्या यह जवाब मददगार था ?"},
    options: [
        {
            label: {en:"Yes", hi:"हाँ"},
            value: "like",
            image: "likeIcon.svg"
        },
        {
            label: {en:"No", hi:"नहीं"},
            value: "dislike",
            image: "dislikeIcon.svg"
        }
    ]
}

export const FeedbackValues = {
    "LIKE": "like",
    "DISLIKE": "dislike"
}