import { v4 as uuidv4 } from 'uuid';

export const dummyBlock = {
    "bot": {
        "text": {
            "1": "dummy",
            "2": ""
        }
    },
    "dummy": true
};

export const anwerTypes = {
    "Validated": "validated",
    "Summarized": "summarized"
}

export const validatedDislikeMessage = {
    en: "We are sorry about this. Please reach out to the help section or try asking another question.",
    hi: "हमें खेद है । कृपया हेल्प सेक्शन में जाएं या कोई अन्य प्रश्न पूछने का प्रयास करें।"
}

export const summarizedAnswerTitle = { en: "This answer is yet to be validated.", hi: "इस उत्तर की पुष्टि होना अभी बाकी है" }

export const botDisclaimerMessage = {
    'en': 'Disclaimer: It is recommended to exercise your own discretion or consult with your ANM for guidance.',
    'hi': 'ध्यान रखें : सलाह दी जाती है कि आप अपने समझदारी का प्रयोग करें या मार्गदर्शन के लिए अपनी ANM से परामर्श लें।'
}
export const disclaimer = {
    en: "The answers provided by the chatbot are based on the training modules published  by the Ministry of Health and Family Welfare, Government of India.",
    hi: "चैटबॉट द्वारा दिए गए उत्तर, भारत सरकार के स्वास्थ्य और परिवार कल्याण मंत्रालय द्वारा प्रकाशित ट्रेनिंग मॉड्यूल पर आधारित हैं।"
}
export const doubt = {
    en: "Type your queries here",
    hi: "अपने प्रश्न यहाँ टाइप करें"
}
export const errorMessageObj = (datetime, userQueryObj) => {
    return {
        "_id": uuidv4(),
        "offset": -1,
        "bot": {
            "datetime": datetime,
            "text": {
                "en": "Something went wrong. Please try again.",
                "hi": "कुछ गड़बड़ हुई है। कृपया दोबारा प्रयास करें।"
            },
            "options": [],
            "suggested_questions": {},
            "sources": [],
            "success": false,
            "error": {
                "code": "303",
                "message": "Internal Server Error"
            },
            "starred": false,
            "welcome": false,
            "answer_type": ""
        },
        "user": {
            "datetime": datetime,
            "type": userQueryObj?.values[0]?.type,
            "text": userQueryObj?.values[0]?.value,
            "image": userQueryObj?.image
        }
    }
}

export const defaultChipBlockText = {
    "html": {
        "en": `<p style=\"display: inline-block;\">
          You may ask a question or select a topic from 
          <img src="./Assets/Images/grid.svg" style=\"width: 1.5rem; margin-left: 0.2rem; margin-right: 0.2rem; display: inline;\" />
        </p>
      </div>`,
        "hi": `<p style=\"display: inline-start;\">
        आप कोई प्रश्न पूछ सकते हैं या 
        <img src='./Assets/Images/grid.svg' style="width: 1.5rem; margin-left: 0.2rem; margin-right: 0.2rem; display: inline;"/> 
        से कोई विषय चुन सकते हैं
    </p>`
    },
    "audio": {
        "en": "You may ask a question or select a topic from. Pregnancy, Tuberculosis, Child Health, Immunisation",
        "hi": "आप कोई प्रश्न पूछ सकते हैं या इनमे से कोई विषय चुन सकते हैं | गर्भावस्था, टीबी, बाल स्वास्थ्य, टीकाकरण"
    }
}
