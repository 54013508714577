const LoadingDots = () => {
    return (
        <div className="flex space-x-3">
            <div className="w-2 h-2 rounded-full bg-gray-700 animate-dot1" />
            <div className="w-2 h-2 rounded-full bg-gray-400 animate-dot2" />
            <div className="w-2 h-2 rounded-full bg-gray-400 animate-dot3" />
        </div>
    )
}

export default LoadingDots;