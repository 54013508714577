import React, { useEffect, useState } from 'react';
import "./NameBar.css";

const NameBar = ({textSize}) => {

  return (
    <div className={`font-semibold flex items-center justify-center gap-2.5 py-4 nameBarDiv`} style={{fontSize: `${textSize}rem`}}>
        <div><img src='/Assets/Images/AppIcon.svg' alt='Asha'/></div>
        HealthVaani
    </div>
  )
}

export default NameBar