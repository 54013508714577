export const langaugeCode = {
    "English" : "en",
    "हिंदी" : "hi"
}

export const languageLabel = {
    "en" : "English",
    "hi" : "हिंदी"
}

export const fontSizeLabel = {
    "en": "A",
    "hi": "आ"
}