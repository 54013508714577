import React from 'react';
import "./NextBtn.css";

const NextBtn = ({value, icon, bgColor, color, nextClick=()=>{}, padx=16}) => {
  return (
    <div className={`nextBtn bg-[${bgColor}] text-[${color}]`} style={{padding: `12px ${padx}px`}} onClick={nextClick}>
        {value}
        <img src={icon} alt={value}/>
    </div>
  )
}

export default NextBtn