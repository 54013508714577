import React from 'react';
import './LoadingChatBlock.css';
import LoadingDots from '../LoadingDots/LoadingDots';

const LoadingChatBlock = ({}) => {
    return (
        <div className='bg-[var(--neutral-white)] rounded-t-lg rounded-br-lg float-left md:inline-block relative mb-4'>
            <div className="flex items-center justify-center h-16 w-20 mx-4">
                <LoadingDots />
            </div>
        </div>
    )
};


export default LoadingChatBlock;