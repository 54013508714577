// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatInputBarDiv {
    border-radius: 10.84px;
    border: 0.759px solid var(--light-neutral-4);
    background: var(--light-neutral-1);
}

.inputDivider {
    border-right: 1px solid var(--dark-neutral-1, 0.5);
    /* opacity: 0.7; */
    height: 28px;
    padding: 0px 5px;
}

.chat-input::-webkit-scrollbar {
    display: none;
}

.chat-input {
    -ms-overflow-style: none;
    scrollbar-width: none;
}`, "",{"version":3,"sources":["webpack://./src/Shared/Components/ChatInputBar/ChatInputBar.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4CAA4C;IAC5C,kCAAkC;AACtC;;AAEA;IACI,kDAAkD;IAClD,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,wBAAwB;IACxB,qBAAqB;AACzB","sourcesContent":[".chatInputBarDiv {\n    border-radius: 10.84px;\n    border: 0.759px solid var(--light-neutral-4);\n    background: var(--light-neutral-1);\n}\n\n.inputDivider {\n    border-right: 1px solid var(--dark-neutral-1, 0.5);\n    /* opacity: 0.7; */\n    height: 28px;\n    padding: 0px 5px;\n}\n\n.chat-input::-webkit-scrollbar {\n    display: none;\n}\n\n.chat-input {\n    -ms-overflow-style: none;\n    scrollbar-width: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
