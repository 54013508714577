// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navBarDiv {
    border-bottom: 1px solid var(--light-neutral-3);
    background: var(--neutral-white);
}`, "",{"version":3,"sources":["webpack://./src/Shared/Components/NavBar/NavBar.css"],"names":[],"mappings":"AAAA;IACI,+CAA+C;IAC/C,gCAAgC;AACpC","sourcesContent":[".navBarDiv {\n    border-bottom: 1px solid var(--light-neutral-3);\n    background: var(--neutral-white);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
