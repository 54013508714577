export const micRecording = {
  title: {
    "en": "Recording in-progress",
    "hi": "रिकॉर्डिंग जारी है"
  },
  subtitle: {
    "en": "Click stop when done",
    "hi": "रिकॉर्डिंग बंद करने के लिए “बंद करें” दबाएँ।"
  },
  stop: {
    "en": "Stop",
    "hi": "बंद करें"
  }
};

export const recordedAudio = {
  title: {
    "en": "Verify your question below",
    "hi": "अपना सवाल जांच लें।"
  },
  recordAgain: {
    "en": "Record again",
    "hi": "दोबारा रिकॉर्ड करें"
  },
  send: {
    "en": "Send",
    "hi": "भेजें"
  }
};

export const deleteAudio = {
  title: {
    "en": "Do you want to delete this recording ?",
    "hi": "क्या आप ये रिकॉर्डिंग डिलीट करना चाहते हैं ?"
  },
  yes: {
    "en": "Yes and record again",
    "hi": "हाँ, फिर से रिकॉर्ड करें"
  },
  no: {
    "en": "No, go back to editing",
    "hi": "नहीं, एडिट पर लौटें"
  }
};
