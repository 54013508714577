import { userType } from "../Constants/UserContants";
import {
  getUserDataApi,
  loginApi,
  registerApi,
  updateProfileApi,
} from "../ENV";
import { fetchApi } from "./FetchAPI";

export async function login(user_id, password) {
  var payloadJSON = {
    user_id: user_id,
    password: password,
    // "type": userType
  };
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
  };
  const res = await fetchApi(
    loginApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );
  return res;
}

export async function register(user_id, phone, password) {
  var payloadJSON = {
    user_id: user_id,
    password: password,
    phone: phone,
  };
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
  };

  const res = await fetchApi(
    registerApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );
  return res.data;
}

export async function resetPassword(access_token, oldPassword, newPassword) {
  var payloadJSON = {
    old_password: oldPassword,
    new_password: newPassword,
  };
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: "Bearer " + access_token,
  };

  const res = await fetchApi(
    updateProfileApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );
  return res.data;
}

export async function updateProfile(access_token, updateObj) {
  var payloadJSON = updateObj;
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: "Bearer " + access_token,
  };

  const res = await fetchApi(
    updateProfileApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );
  return res.data;
}

export async function getUserData(access_token) {
  var headerValue = {
    "x-access-token": access_token,
  };
  const res = await fetchApi(getUserDataApi, "GET", {}, headerValue, {});
  return res.Response;
}
