import React, { useEffect, useState, useRef } from "react";
import BottomBarDeviceReadinessRecording from "../BottomBarDeviceReadinessRecording/BottomBarDeviceReadinessRecording";
import {
  stopPlayingAudio,
} from "../../../Actions/Utils";

const Device_readiness_Record = ({
  project,
  selectedLanguage,
}) => {
  const audioRef = useRef(null);
  const [speakerEnabled, setSpeakerEnabled] = useState(false);

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showOnlinePopup, setShowOnlinePopup] = useState(false);
  const [isTryingToConnect, setIsTryingToConnect] = useState(false);
  const [showStatusBar, setShowStatusBar] = useState(false);
  const [constants, setConstants] = useState(null);


  useEffect(() => {
    stopPlayingAudio(audioRef);
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
  
    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setSpeakerEnabled(false);
    };

    const loadConstants = async () => {
      const module = await import(`../../../Constants/${project}/ChatConstants`);
      setConstants(module);
    }

    loadConstants();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    }
  }, []);

  useEffect(() => {
    if (showOnlinePopup ) {
      const timer = setTimeout(() => {
        setShowOnlinePopup(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showOnlinePopup]);


  useEffect(() => {
    if (showOnlinePopup || isTryingToConnect) {
      setShowStatusBar(true);
    } else {
      setShowStatusBar(false);
    }
  }, [showOnlinePopup, isTryingToConnect]);
  

  const handleOnline = () => {
    setIsOnline(true);
    setShowOnlinePopup(true);
    setIsTryingToConnect(false);
  };

  const handleOffline = () => {
    setIsOnline(false);
    setShowOnlinePopup(true);
  };




  return (
    <div>

      <div
        className="flex flex-col h-screen bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url("/Assets/Images/bgWallpaper.svg")`,
          boxShadow: showStatusBar ? "0px 10px 20px rgba(0, 0, 0, 0.1)" : "none",
          backgroundColor: showStatusBar ? "#FCE1E1" : "transparent",
          zIndex: showStatusBar ? "10000" : "0",
        }}
      >
        <BottomBarDeviceReadinessRecording
          project={project}
          selectedLanguage={selectedLanguage}
        />
      </div>
      
    </div>
  );
};

export default Device_readiness_Record;





