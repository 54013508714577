// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.consentContentWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.contentHeader {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--almost-black);
    font-size: 16px;
    font-weight: 500;
    font-family: "Noto Sans";
    line-height: 150%;
}

.contentText {
    color: var(--dark-neutral-2);
    font-size: 14px;
    font-weight: 400;
    font-family: "Noto Sans";
    line-height: 150%;
}
`, "",{"version":3,"sources":["webpack://./src/Shared/Components/ConsentContent/ConsentContent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,0BAA0B;IAC1B,eAAe;IACf,gBAAgB;IAChB,wBAAwB;IACxB,iBAAiB;AACrB;;AAEA;IACI,4BAA4B;IAC5B,eAAe;IACf,gBAAgB;IAChB,wBAAwB;IACxB,iBAAiB;AACrB","sourcesContent":[".consentContentWrapper {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n\n.contentHeader {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n    color: var(--almost-black);\n    font-size: 16px;\n    font-weight: 500;\n    font-family: \"Noto Sans\";\n    line-height: 150%;\n}\n\n.contentText {\n    color: var(--dark-neutral-2);\n    font-size: 14px;\n    font-weight: 400;\n    font-family: \"Noto Sans\";\n    line-height: 150%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
