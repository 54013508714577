import React from 'react';
import "./Disclaimer.css";
import {disclaimer} from '../../../Constants/ASHA/ChatConstants';
const Disclaimer = ({selectedLanguage}) => {
  return (
    <div className='w-[65vw] rounded-md border border-[var(--disclaimer-2)] bg-[var(--disclaimer-3)] p-3'>
      <div className='text-[var(--disclaimer-1)] text-sm font-normal leading-4'>
        <span className='inline-flex items-start justify-center text-center'>
        <img src={"/Assets/Images/lock.svg"} alt='lock'/>
          {disclaimer[selectedLanguage]}
          {/* Lorem ipsum dolor sit amet
          One more line of something text we need to figure out, it should end here. */}
        </span>
      </div>
    </div>
  )
}

export default Disclaimer