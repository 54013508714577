import { FeedbackConstant, FeedbackValues } from "../../../Constants/AWW/FeedbackConstants";

const FeedbackButtonBlock = ({feedback, selectedLanguage}) => {
    return (
        <div>
        {
            (feedback == FeedbackValues.LIKE) ?
            <div className={'flex items-center justify-center px-4 py-3 rounded-xl border-2 bg-[var(--green-3)] text-[var(--green-1)] border-[var(--green-2)]'}>
                <img src={'./Assets/Images/' + FeedbackConstant.options[0].image} />
                <span className="text-lg ml-2">{FeedbackConstant.options[0].label[selectedLanguage]}</span>
            </div>
            : <div className={'flex items-center justify-center px-4 py-3 rounded-xl border-2 bg-[var(--red-3)] text-[var(--red-1)] border-[var(--red-2)]'}>
                <img src={'./Assets/Images/' + FeedbackConstant.options[1].image} />
                <span className="text-lg ml-2">{FeedbackConstant.options[1].label[selectedLanguage]}</span>
            </div>
        }
        </div>
    )
}

export default FeedbackButtonBlock;


  