import React, { useEffect, useState } from "react";
import { languageLabel } from "../../../Constants/Language-Constants";
import NextBtn from "../../../Shared/Components/NextBtn/NextBtn";
import "./Introduction.css";
import { generateSpeech } from "../../../Actions/ChatActions";
import { getUserToken, stopPlayingAudio } from "../../../Actions/Utils";
import { useNavigate } from "react-router-dom";

const Introduction = ({ project, selectedLanguage, setSelectedLanguage = () => {}, audioRef }) => {
  const navigate = useNavigate();
  const [showSpeakerIcon, setShowSpeakerIcon] = useState(false);
  const [showSpeakerActive, setShowSpeakerActive] = useState(false);
  const [requestController, setRequestController] = useState(null);
  const [constants, setConstants] = useState(null);

  const toggleLanguage = (e) => {
    setSelectedLanguage(e);
  };

  const nextClick = () => {
    navigate("/login");
  };

  useEffect(() => {
    const handleAudio = async () => {
      const url = await generateSpeech(langPreferenceConstantGuide[selectedLanguage], selectedLanguage);
      const newAudio = new Audio(url);
      if (audioRef.current) {
        audioRef.current.pause();
      }
      if (requestController) {
        requestController.abort();
      }

      audioRef.current = newAudio;
      newAudio.play().catch((err) => {
        console.error("Error playing audio:", err);
      });
    };
    if (showSpeakerActive) handleAudio();
  }, [showSpeakerActive]);


  useEffect(() => {
    const loadConstants = async () => {
      const module = await import(`../../../Constants/${project}/IntroductionConstants`);
      setConstants(module);
    };

    loadConstants();

    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setShowSpeakerActive(false);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, []);

  if (!constants) {
    return <div>Loading...</div>;
  }

  const { nextConstant, languagePreferenceConstant, langPreferenceConstantGuide, guideButtons, title } = constants;

  const createMarkup = () => ({ __html: languagePreferenceConstant[selectedLanguage] });
  return (
    <div
      className="bg-[#3237A0] h-screen w-screen relative bg-blend-color-burn"
      style={{ backgroundImage: `url("/Assets/Images/wallpaperBg.jpg")` }}
    >
      <div className="relative h-full flex flex-col items-center gap-28">
        <div className="w-full p-6 flex justify-end ">
            <div 
              className={`speakerDiv ${
                showSpeakerIcon && showSpeakerActive ? "Active" : ""
              }   `}
              onClick={() => {
                setShowSpeakerActive(!showSpeakerActive);
                stopPlayingAudio(audioRef);
              }}
            >
              {showSpeakerIcon && showSpeakerActive ? guideButtons.stop[selectedLanguage] : null}
              {showSpeakerActive ? (
                <div className="">
                  <img
                    src={"/Assets/Images/blueSpeaker.svg"}
                    alt="active Speaker"
                  />
                </div>
              ) : (
                <div className="">
                  <img src={"/Assets/Images/crossSpeakerIcon.svg"} />
                </div>
              )}
            </div>

        </div>

        <div className="flex items-center gap-4">
          <img
            src={"/Assets/Images/" + project + "/AppIcon.svg"}
            alt="Asha"
            className="h-16 w-16 rounded-full"
          />
          <div>
            <div className="text-2xl font-bold tracking-[2px] intro-heading">
              {title[selectedLanguage].heading}
            </div>
            <div className="text-2xl font-bold intro-subheading">{title[selectedLanguage].sub_heading}</div>
          </div>
        </div>
        <div className="flex flex-col text-center gap-5 px-[20%]">
          <div className="font-medium text-[var(--neutral-white)] tracking-[0.15px] intro-lang whitespace-pre-line" dangerouslySetInnerHTML={createMarkup()}>
          </div>
          <div className="into-lang-buttons">
            <div
              className={`flex justify-center h-[100%] w-[50%] py-1.5 ${
                selectedLanguage === "hi"
                  ? "introselectedLngBtn"
                  : "intronormalBtn"
              }`}
              onClick={() => toggleLanguage("hi")}
            >
              {languageLabel["hi"]}
            </div>
            <div
              className={`flex justify-center h-[100%] w-[50%] py-1.5  ${
                selectedLanguage === "en"
                  ? "introselectedLngBtn"
                  : "intronormalBtn"
              }`}
              onClick={() => toggleLanguage("en")}
            >
              {languageLabel["en"]}
            </div>
          </div>
        </div>
        <div>
          <NextBtn
            // value={"Next"}
            value={nextConstant[selectedLanguage]}
            icon={"/Assets/Images/nextBlueIcon.svg"}
            bgColor={"var(--neutral-white)"}
            color={"var(--primary-dark)"}
            nextClick={nextClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Introduction;
