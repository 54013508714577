import React, { useEffect, useState } from "react";
import { languageLabel } from "../../../Constants/Language-Constants";
import NextBtn from "../../../Shared/Components/NextBtn/NextBtn";
import "./Device_readiness_Intro.css";
import { generateSpeech } from "../../../Actions/ChatActions";
import { getUserToken, stopPlayingAudio } from "../../../Actions/Utils";

import { useNavigate } from "react-router-dom";
const Device_readiness_Intro = ({ project, selectedLanguage, setSelectedLanguage = () => {}, audioRef }) => {
  const navigate = useNavigate();
  const [showSpeakerIcon, setShowSpeakerIcon] = useState(false);
  const [showSpeakerActive, setShowSpeakerActive] = useState(false);
  const [requestController, setRequestController] = useState(null);
  const [constants, setConstants] = useState(null);


  const toggleLanguage = (e) => {
    setSelectedLanguage(e);
  };

  const nextClick = () => {
    navigate("/device_readiness/consent");
  };
  useEffect(() => {
    stopPlayingAudio(audioRef);
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    const handleAudio = async () => {
      const url = await generateSpeech(langPreferenceConstantGuide[selectedLanguage], selectedLanguage);
      const newAudio = new Audio(url);
      if (audioRef.current) {
        audioRef.current.pause();
      }
      if (requestController) {
        requestController.abort();
      }

      audioRef.current = newAudio;
      newAudio.play().catch((err) => {
        console.error("Error playing audio:", err);
      });
    };
    if (showSpeakerActive) handleAudio();
  }, [showSpeakerActive]);

  
  useEffect(() => {
    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setShowSpeakerActive(false);
    };

    const loadConstants = async () => {
      const module = await import(`../../../Constants/${project}/IntroductionConstants`);
      setConstants(module);
    }

    loadConstants();

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, []);

  if (!constants) {
    return <div>Loading...</div>;
  }

  const { proceedConstant,langPreferenceConstantGuide,guideButtons, devicereadinessAshaIntro, devicereadinessAshaShort, devicereadinessAshaDescriptive } = constants;

  return (
    <div
      className="bg-[#3237A0] overflow-y-scroll  h-screen w-screen relative bg-blend-color-burn"
      style={{ backgroundImage: `url("/Assets/Images/wallpaperBg.jpg")` }}
    >
      <div className="relative h-full  justify-between flex flex-col items-center ">
        <div className="w-full p-6 flex justify-end ">
            <div 
              className={`speakerDiv ${
                showSpeakerIcon && showSpeakerActive ? "Active" : ""
              }   `}
              onClick={() => {
                setShowSpeakerActive(!showSpeakerActive);
                stopPlayingAudio(audioRef);
              }}
            >
              {showSpeakerIcon && showSpeakerActive ? guideButtons.stop[selectedLanguage] : null}
              {showSpeakerActive ? (
                <div className="">
                  <img
                    src={"/Assets/Images/blueSpeaker.svg"}
                    alt="active Speaker"
                  />
                </div>
              ) : (
                <div className="">
                  <img src={"/Assets/Images/crossSpeakerIcon.svg"} />
                </div>
              )}
            </div>

        </div>

        <div className="flex items-center pb-10">
          <img
            src={"/Assets/Images/asha-sq-icon.png"}
            alt="Asha"
            className="h-[67px] w-[67px] rounded-sm"
          />

        </div>
        <div className=" flex items-center text-2xl font-bold  intro-heading pb-6">
            {devicereadinessAshaIntro[selectedLanguage]}
        </div>
        <div className=" flex items-center    intro-heading pb-6">
        {devicereadinessAshaShort[selectedLanguage]}
        

        </div>
        <div className="flex items-center pb-6 ">
          <img
            src={"/Assets/Images/dash-device-readiness.png"}
            alt="Asha"
            className=" rounded-sm"
          />

        </div>
        <div className=" text-center items-center  intro-heading px-10 pb-6 break-all">
        {devicereadinessAshaDescriptive[selectedLanguage]}
        </div>

        <div className="pb-20">
          <NextBtn
            // value={"Next"}
            value={proceedConstant[selectedLanguage]}
            icon={"/Assets/Images/nextBlueIcon.svg"}
            bgColor={"var(--neutral-white)"}
            color={"var(--primary-dark)"}
            nextClick={nextClick}
            
          />
        </div>


        <div className="flex flex-col text-center pb-[5%] px-[20%]">
          {/* <div className="font-medium text-[var(--neutral-white)] tracking-[0.15px] intro-lang whitespace-pre-line" dangerouslySetInnerHTML={createMarkup()}>
          </div> */}
          <div className="into-lang-buttons">
            <div
              className={`flex justify-center h-[100%] w-[50%] py-1.5 ${
                selectedLanguage === "hi"
                  ? "introselectedLngBtn"
                  : "intronormalBtn"
              }`}
              onClick={() => toggleLanguage("hi")}
            >
              {languageLabel["hi"]}
            </div>
            <div
              className={`flex justify-center h-[100%] w-[50%] py-1.5  ${
                selectedLanguage === "en"
                  ? "introselectedLngBtn"
                  : "intronormalBtn"
              }`}
              onClick={() => toggleLanguage("en")}
            >
              {languageLabel["en"]}
            </div>
          </div>
        </div>

        

        <div className="  h-1/6 flex flex-col align-center items-center bg-[var(--primary-light)] justify-center w-full pb-0">
          <p className="items-center align-center">Powered by</p>
          <img
            src={"/Assets/Images/nhsrc.svg"}
            alt="Asha"
            className="h-[67px] w-[67px]"
          />
        </div>

      </div>
    </div>
  );
};

export default Device_readiness_Intro;
