const DefaultChips = ({
    option,
    selectedLanguage,
    send = () => {},
    textSize,
    disabled,
    index,
    selectedChipIndex, 
    isLoading
  }) => {
    function saveResponse(input, image) {
      send(index, input, image);
    }
  
    return (
      <div
        className={`flex items-center justify-center rounded-lg border border-solid border-1 border-[var(--primary-dark)] gap-3 text-[var(--primary-dark)] bg-[var(--neutral-white)]  min-h-[3rem]   ${isLoading ? 'pointer-events-none' : ''}`}
        onClick={() => saveResponse(option.value[selectedLanguage], option.img)}
        style={{ fontSize: `${textSize}rem` }}
      >
        {option.img && <img
          src={option.img}
          alt={option.value[selectedLanguage]}
          className="h-[100%]"
        />}
        {option.value[selectedLanguage]}
      </div>
    );
  };
  
  export default DefaultChips;
  