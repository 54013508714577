import React from "react";
import NameBar from "../../../Shared/Components/NameBar/NameBar";
import NavBar from "../../../Shared/Components/NavBar/NavBar";
import SideMenu from "../SideMenu/SideMenu";
import HelpDialog from "../../../Shared/Components/HelpDialog/HelpDialog";

const TopBar = ({
  project,
  userData,
  isMenuOpen,
  toggleMenu = () => {},
  selectedLanguage,
  setSelectedLanguage = () => {},
  textSize,
  decreaseTextSize = () => {},
  increaseTextSize = () => {},
  isHelpOpen,
  toggleHelp = () => {},
}) => {
  return (
    <div className="">
      <NavBar project={project} selectedLanguage={selectedLanguage} textSize={textSize} toggleMenu={toggleMenu} toggleHelp={toggleHelp} />
      {/* <NameBar textSize={textSize} /> */}
      {isHelpOpen && (
        <div className="absolute top-0 left-0 h-screen w-screen z-20">
          <HelpDialog project={project} textSize={textSize} toggleHelp={toggleHelp} />
        </div>
      )}
      {isMenuOpen && (
        <SideMenu
          project={project}
          userData={userData}
          toggleMenu={toggleMenu}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          textSize={textSize}
          decreaseTextSize={decreaseTextSize}
          increaseTextSize={increaseTextSize}
        />
      )}
    </div>
  );
};

export default TopBar;
