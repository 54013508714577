import React, { useEffect, useState, useRef } from "react";
import TopBar from "../TopBar/TopBar";
import BottomBar from "../BottomBar/BottomBar";
import ChatQueue from "../ChatQueue/ChatQueue";
import SourceDialog from "../../../Shared/Components/SourceDialog/SourceDialog";
import {
  getMessages,
  getProcessedCustomPayload,
  getSessionID,
  getUserMessageBlock,
  getUserToken,
  stopPlayingAudio,
} from "../../../Actions/Utils";
import { useNavigate } from "react-router-dom";
import { sendChatFeedback, starBotAnswer, submit } from "../../../Actions/ChatActions";
import Loader from "../../../Shared/Components/Loader/Loader";

const ChatBody = ({
  project,
  selectedLanguage,
  textSize,
  setSelectedLanguage = () => {},
  decreaseTextSize = () => {},
  increaseTextSize = () => {},
}) => {
  let navigate = useNavigate();
  const [inputText, setInputText] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [chatArr, setChatArr] = useState([]);
  const userProfileData = JSON.parse(sessionStorage.getItem("userProfileData"));
  const [userData, setUserData] = useState(userProfileData || null);
  const [isLoading, setIsLoading] = useState(false);
  const [sourceDialogFlag, setSourceDialogFlag] = useState(false);
  const [selectedSourceData, setSelectedSourceData] = useState(null);
  const [currentBlock, setCurrentBlock] = useState(null);
  const chatBodyRef = useRef(null);
  const [currentOffset, setCurentOffset] = useState(null);
  const [messageLoader, setMessageLoader] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const audioRef = useRef(null);
  const [speakerEnabled, setSpeakerEnabled] = useState(false);
  const [constants, setConstants] = useState(null);

  useEffect(() => {
    if (getUserToken()) {
      populateChat();
    } else {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    }

    const loadConstants = async () => {
      const module = await import(`../../../Constants/${project}/ChatConstants`);
      setConstants(module);
    };

    loadConstants();

    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setSpeakerEnabled(false);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = () => {
      window.history.pushState(null, document.title, window.location.href);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate, project]);

  useEffect(() => {
    if (audioRef.current) {
      setSpeakerEnabled(false);
      stopPlayingAudio(audioRef);
    }
  }, [isHelpOpen, inputText, isMenuOpen, sourceDialogFlag]);

  const handleScroll = () => {
    if (currentOffset > 0 && chatBodyRef.current.scrollTop === 0) {
      populateChat(currentOffset);
    }
  };

  useEffect(() => {
    const chatBodyCurrent = chatBodyRef.current;
    if (chatBodyCurrent) {
      chatBodyCurrent.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatBodyCurrent) {
        chatBodyCurrent.removeEventListener("scroll", handleScroll);
      }
    };
  }, [currentOffset]);

  async function populateChat(offset = null) {
    setMessageLoader(true);
    try {
      const res = await getMessages(offset);
      if (res["error"]) {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
      } else {
        let arr = [];
        res["Response"].forEach((message, idx) => {
          if (idx === res["Response"].length - 1) {
            setCurentOffset(message.offset - 1);
          }
          arr.unshift(message);
        });
        setChatArr((chatArr) => [...arr, ...chatArr]);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setMessageLoader(false);
    }
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleHelp = () => {
    setIsHelpOpen(!isHelpOpen);
  };

  const openSourceDialog = (sourceData) => {
    setSourceDialogFlag(true);
    setSelectedSourceData(sourceData);
  };

  const closeSourceDialog = () => {
    setSourceDialogFlag(false);
    setSelectedSourceData(null);
  };

  function appendUserChatBlock(arr, input, image = null) {
    let defaultFlag = arr[arr.length - 1]?.bot?.welcome || false;
    let block = getUserMessageBlock(input?.selectedLanguage || input, defaultFlag, image);
    arr.push(block);
    setCurrentBlock(block);
    const newArr = appendLoaderBlock(arr);
    setChatArr(newArr);
    setIsLoading(true);
    return newArr;
  }

  function appendLoaderBlock(arr) {
    arr[arr.length - 1]["bot"] = dummyBlock["bot"];
    arr[arr.length - 1]["dummy"] = dummyBlock["dummy"];
    setCurrentBlock(arr[arr.length - 1]);
    return arr;
  }

  function updateLastPromptSelectedChip(index) {
    let arr = [...chatArr];
    if (arr[arr.length - 1]?.bot) {
      arr[arr.length - 1].bot.selectedChipIndex = index;
    }
    return arr;
  }

  async function submitResponse(index, input, customQueryFlag, image = null) {
    let arr = updateLastPromptSelectedChip(index);
    arr = appendUserChatBlock(arr, input, image);
    submit(
      getUserToken(),
      getSessionID(),
      getProcessedCustomPayload(input, selectedLanguage, customQueryFlag, image)
    ).then((res) => {
      setIsLoading(false);
      if (res.error) {
        arr[arr.length - 1]["bot"]["error"]= {"code": res.error}
        arr[arr.length - 1]["bot"]["datetime"]= new Date();
        delete arr[arr.length - 1]["dummy"];
      }
      else {
        arr[arr.length - 1] = res.response;
        delete arr[arr.length - 1]["dummy"];
        setChatArr(arr);
        setCurrentBlock(arr[arr.length - 1]);
      }
    });
  }

  async function sendFeedback(feedback) {
    let temp = [...chatArr];
    temp[temp.length - 1].bot.feedback_type = feedback.value;
    sendChatFeedback(
      getUserToken(),
      getSessionID(),
      temp[temp.length - 1]._id,
      feedback.value
    ).then((response) => {
      setChatArr(temp);
    });
  }

  async function markFavourite(idx, id, starFlag) {
    let arr = [...chatArr];
    arr[idx]["bot"]["starred"] = starFlag;
    starBotAnswer(getUserToken(), getSessionID(), id, starFlag).then((response) => {
      if (response.error) {
        localStorage.clear();
        sessionStorage.clear();
        navigate("/login");
        return null;
      }
      setChatArr(arr);
    });
  }

  if (!constants) {
    return <div>Loading...</div>;
  }

  const { dummyBlock } = constants;

  return (
    <div>
      <div
        className="flex flex-col h-screen bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url("/Assets/Images/bgWallpaper.svg")`,
        }}
      >
        <TopBar
          project={project}
          userData={userData}
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          textSize={textSize}
          decreaseTextSize={decreaseTextSize}
          increaseTextSize={increaseTextSize}
          isHelpOpen={isHelpOpen}
          toggleHelp={toggleHelp}
        />
        <div
          className="overflow-hidden overflow-y-scroll scroll-smooth min-h-0 flex-grow"
          ref={chatBodyRef}
        >
          {messageLoader && (
            <div className="absolute w-[100vw] z-[2] flex justify-center my-4">
              <Loader />
            </div>
          )}
          <div className="flex items-center justify-center pt-2"></div>
          <div className="py-2 md:px-6 px-2">
            {chatArr.length > 0 && (
              <ChatQueue
                project={project}
                selectedLanguage={selectedLanguage}
                chatArr={chatArr}
                isLoading={isLoading}
                openSourceDialog={openSourceDialog}
                populateChat={populateChat}
                currentBlock={currentBlock}
                textSize={textSize}
                sendFeedback={sendFeedback}
                submitResponse={submitResponse}
                audioRef={audioRef}
                speakerEnabled={speakerEnabled}
                setSpeakerEnabled={setSpeakerEnabled}
                markFavourite={markFavourite}
              />
            )}
          </div>
        </div>
        <BottomBar
          audioRef={audioRef}
          project={project}
          input={inputText}
          setInput={setInputText}
          selectedLanguage={selectedLanguage}
          send={submitResponse}
          textSize={textSize}
          isLoading={isLoading}
          stopPlayingAudio={stopPlayingAudio}
          setSpeakerEnabled={setSpeakerEnabled}
        />
      </div>
      {sourceDialogFlag && (
        <div className="absolute top-0 left-0 h-screen w-screen z-20">
          <SourceDialog
            selectedLanguage={selectedLanguage}
            sourceData={selectedSourceData}
            closeDialog={closeSourceDialog}
          />
        </div>
      )}
    </div>
  );
};

export default ChatBody;
