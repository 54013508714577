// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimerDiv {
    width: 65vw;
    border-radius: 4px;
    border: 1px solid var(--disclaimer-2);
    background-color: var(--disclaimer-3);
    padding: 9px 13px;
}

.disContent {
    color: var(--disclaimer-1);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Shared/Components/Disclaimer/Disclaimer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,qCAAqC;IACrC,qCAAqC;IACrC,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;IAC1B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".disclaimerDiv {\n    width: 65vw;\n    border-radius: 4px;\n    border: 1px solid var(--disclaimer-2);\n    background-color: var(--disclaimer-3);\n    padding: 9px 13px;\n}\n\n.disContent {\n    color: var(--disclaimer-1);\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
