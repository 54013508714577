const UserChatBlock = ({ image, text, textSize }) => {
  return (
    <div
      className="flex items-center justify-start float-right bg-[var(--primary-dark)] py-2 px-6 text-[color:var(--neutral-white)] leading-5 rounded-t-lg rounded-bl-lg max-w-[77.5%] mb-2 min-h-[3.5rem]"
      style={{ fontSize: `${textSize}rem` }}
    >
      {image && (
        <img className="h-[100%]" src={"." + image} />
      )}
      <span className="ml-2">{text}</span>
    </div>
  );
};

export default UserChatBlock;
