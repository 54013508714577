export const sideMenu = [
    {
        "id": "view tutorial",
        "value": {
            "English": "View Tutorial",
            "हिंदी": "View Tutorial",
        },
        "icon": "/Assets/Images/playCircle.svg",
    },
    {
        "id": "give feedback",
        "value": {
            "English": "Give feedback",
            "हिंदी": "Give Feedback",
        },
        "icon": "/Assets/Images/messageSquare.svg",
    },
    {
        "id": "technical support",
        "value": {
            "English": "Technical Support",
            "हिंदी": "Technical Support",
        },
        "icon": "/Assets/Images/supportCircle.svg",
    },
]