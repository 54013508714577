const AWS = require('aws-sdk');

// AWS.config.update({
//     accessKeyId: JSON.parse(sessionStorage.getItem('pollyToken'))?.accessKeyId,
//     secretAccessKey: JSON.parse(sessionStorage.getItem('pollyToken'))?.secretAccessKey,
//     region: JSON.parse(sessionStorage.getItem('pollyToken'))?.region
// });

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID_POLLY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_POLLY,
    region: process.env.REACT_APP_AWS_REGION,
});


export default AWS;
