import React from 'react';
import "./ConsentContent.css";

const ConsentContent = ({icon, header, text}) => {
  return (
    <div className='flex flex-col gap-1'>
        <div className='flex item-center gap-1 text-[color:var(--almost-black)] text-base font-medium font-["Noto_Sans"]'>
            <img src={icon} alt={header}/>
            {header}
        </div>
        <div className='text-[color:var(--dark-neutral-2)] text-sm font-normal font-["Noto_Sans"]'>{text}</div>
    </div>
  )
}

export default ConsentContent