import axios from "axios";
import {
  chatFeedbackApi,
  fetchDataApi,
  pollyTokenApi,
  starBotAnswerApi,
  submitApi,
  transcribeApi,
  verifyPollyTokenApi,
} from "../ENV";
import { convertTextToSpeech } from "./AWS/AWSPolly";
import { fetchApi } from "./FetchAPI";


const project = process.env.REACT_APP_PROJECT;
var errorMessageObj = null
const loadConstants = async () => {
  const module = await import(`../Constants/${project}/ChatConstants`);
  errorMessageObj = module.errorMessageObj;
};

loadConstants().then(() => {
  console.log('Constants loaded');
}).catch(err => {
  console.error('Error loading constants:', err);
});

export async function fetchMessages(access_token, session_id, offset) {
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token,
    "session-id": session_id
  };
  const res = await fetchApi(
    fetchDataApi,
    "GET",
    {},
    headerValue,
    offset ? { offset: offset } : {}
  );
  
  return res;
}

export async function submit(access_token, session_id, queryObj) {

  try {
    var payloadJSON = queryObj;
    var headerValue = {
      Accept: "application/json",
      "Content-type": "application/json",
      "x-access-token": access_token,
      "session-id": session_id
    };

    const res = await fetchApi(
      submitApi,
      "POST",
      JSON.stringify(payloadJSON),
      headerValue,
      {}
    );
    return res;
  } catch(e) {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/login';
  }
}


export async function transcribe(access_token, session_id, langaugeCode, audioBlob) {
    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: transcribeApi,
      headers: {
        "x-access-token": access_token,
        "session-id": session_id,
        "x-lang-code": langaugeCode,
        "Content-Type": "audio/mpeg"
      },
      data: audioBlob
    };
    const res = await axios(config);
    return res;
}

export async function getPollyToken(access_token, session_id) {
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token,
    "session-id": session_id
  };

  const res = await fetchApi(pollyTokenApi, "GET", {}, headerValue, {});
  return res;
}

export async function verifyPollyToken(access_token, session_id, pollyToken) {
  var paramsDic = {
    token: pollyToken,
  };
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token,
    "session-id": session_id
  };

  const res = await fetchApi(
    verifyPollyTokenApi,
    "GET",
    {},
    headerValue,
    paramsDic
  );
  return res.data;
}

export async function generateSpeech(text, langCode) {
  const url = await convertTextToSpeech(text, langCode);
  return url;
}

// export function generateSpeechText(selectedLanguageCode, audioBlob) {
//     var formData = new FormData();
//     formData.append("file", audioBlob, 'audio-sample.mp3');
//     var config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: speechToTextApi,
//       headers: {
//         "x-lang-code": languageName[selectedLanguageCode],
//         "Content-Type": "audio/mpeg",
//       },
//       data: formData,
//     };

//     return axios(config)
//             .then(res  => {
//               return res.data.text;
//             })
//   }


export async function sendChatFeedback(access_token, session_id, chat_block_id, feedback_type, feedback='') {
  var payloadJSON = {
    chat_id: chat_block_id,
    feedback_type: feedback_type,
    feedback: feedback
  };
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token,
    "session-id": session_id
  };

  const res = await fetchApi(
    chatFeedbackApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );
  return res.data;
}

export async function starBotAnswer(access_token, session_id, chat_block_id, starFlag) {
  var payloadJSON = {
    chat_id: chat_block_id,
    starred: starFlag
  };
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    "x-access-token": access_token,
    "session-id": session_id
  };

  const res = await fetchApi(
    starBotAnswerApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );

  return res;
}