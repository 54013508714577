// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes dot-animation {
    0%, 100% { opacity: 1; }
    33% { opacity: 0.3; }
}

.animate-dot1 {
    animation: dot-animation 1.5s infinite;
    animation-delay: 0s;
}

.animate-dot2 {
    animation: dot-animation 1.5s infinite;
    animation-delay: 0.5s;
}

.animate-dot3 {
    animation: dot-animation 1.5s infinite;
    animation-delay: 1s;
}`, "",{"version":3,"sources":["webpack://./src/Shared/Components/LoadingChatBlock/LoadingChatBlock.css"],"names":[],"mappings":"AAAA;IACI,WAAW,UAAU,EAAE;IACvB,MAAM,YAAY,EAAE;AACxB;;AAEA;IACI,sCAAsC;IACtC,mBAAmB;AACvB;;AAEA;IACI,sCAAsC;IACtC,qBAAqB;AACzB;;AAEA;IACI,sCAAsC;IACtC,mBAAmB;AACvB","sourcesContent":["@keyframes dot-animation {\n    0%, 100% { opacity: 1; }\n    33% { opacity: 0.3; }\n}\n\n.animate-dot1 {\n    animation: dot-animation 1.5s infinite;\n    animation-delay: 0s;\n}\n\n.animate-dot2 {\n    animation: dot-animation 1.5s infinite;\n    animation-delay: 0.5s;\n}\n\n.animate-dot3 {\n    animation: dot-animation 1.5s infinite;\n    animation-delay: 1s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
