import "./ChatInputBar.css";
import { useEffect, useState } from "react";

const ChatInputBar = ({
  project,
  selectedLanguage,
  textareaRef,
  optionSelected,
  input,
  setOptionSelected = () => {},
  setInput = () => {},
  send = () => {},
  adjustTextareaHeight = () => {},
  textSize,
  isLoading
}) => {

  const[constants, setConstants] = useState(null);

  function toggleOptionSelected() {
    setOptionSelected(!optionSelected);
  }

  function sendText() {
    if (!isLoading && input.length > 0) {
      send(-1, input, true, null);
      setInput("");
      adjustTextareaHeight(true);
    }
  }

  useEffect(() => {
    const loadConstants = async () => {
      const module = await import(`../../../Constants/${project}/ChatConstants`);
      setConstants(module);
    }

    loadConstants();
  },[]);

  if (!constants) {
    return <div>Loading...</div>;
  }

  const { doubt } = constants;

  return (
    <div className="bg-[--neutral-white] w-[75vw] flex items-center justify-start py-1 px-2 chatInputBarDiv">
      <div onClick={toggleOptionSelected}>
        <img
          src={
            optionSelected
              ? "/Assets/Images/InputImageSelected.svg"
              : "/Assets/Images/InputImage.svg"
          }
          alt="User Input"
        />
      </div>
      <div className="inputDivider" />
      <textarea
        ref={textareaRef}
        rows="1"
        placeholder={doubt[selectedLanguage]}
        className="chat-input p-2 rounded-xl w-[90%] outline-none resize-none placeholder:italic placeholder:text-base"
        value={input}
        onChange={(event) => {
          setInput(event.target.value);
          event.target.value.length > 0
            ? adjustTextareaHeight()
            : adjustTextareaHeight(true);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            sendText();
          }
        }}
        style={{
          fontSize: `${textSize}rem`,
        }}
      />
    </div>
  );
};

export default ChatInputBar;
